import { ProgressIndicator, ProgressStep, Tag } from "@carbon/react";
import { CheckmarkOutline, WarningHex } from "@carbon/react/icons/index";
import React, { useEffect, useState } from "react";

const BizopsProgress = (props) => {
  const [progressLine, setProgressLine] = useState([
    {
      day: [],
      progress: "complete",
      title: {
        title: "Start",
        value: "",
        name: "Start"
      },
      label: null,
    },
    {
      day: ["06"],
      progress: "error",
      title: {
        title: "Add Minutes",
        value: "97.78%",
        name: "",
      },
      label: [],
    },
    {
      day: [],
      progress: "complete",
      title: {
        title: "End",
        name: "End",
        value: "",
      },
      label: null,
    },
  ]);

  useEffect(() => {
    if(props.toggleLab) {
      updateProcess(props.businessProcess)
    } else {
      updateProcess("")
    }

    console.log("props", props)

    let newAr = []
    props.domainDetails.length > 0 && props.domainDetails[0].activity.model.nodes.map((node) => {
      if (!["START","STOP"].includes(node.activityName)){
        newAr.push(node);
      }
    })
    const statPrev = progressLine.map((prev) => {
      return prev.title.title === "Add Minutes" ? {
        ...prev,
        title: {
          ...prev.title,
          name: props.domainDetails.length > 0 && props.domainDetails[0].title	
        },
        label: prev.label !== null ? newAr.map((lab) => {
          const devi = props.activityDetails !== undefined && props.activityDetails.data.filter((dev) => dev.activityName !== undefined && lab.activityName === dev.activityName)
           if(devi.length > 0) {
            return {
              ...lab,
              value: 97
            }
           } else {
            return {
              ...lab,
              value: 99
            }
           }
         }) : null
      } : prev
    })

    setProgressLine(statPrev)
  }, [props])

  const updateProcess = (proc) => {
    setProgressLine(prev => prev.map((pr) => {
      return {
        ...pr,
        label : pr.label !== null ? pr.label.map((lab) => {
          return {
            ...lab,
            active: lab.title === proc ? "active": "inactive"
          }
        }) : []
      }
    }))
  }

  const DrawProgress = () => {
    return progressLine.map((flow) => {
      return (
        <ProgressStep
          label={
            <p
              title={flow.title.title}
              onClick={(e) =>
                flow.title.title === "Start" || flow.title.title === "End"
                  ? undefined
                  : secondaryLabel(e, flow.title.title)
              }
            >
              {flow.title.name}{" "}
              {/* <span>
                {flow.title.value}
                <span className="pt"></span>
              </span> */}
            </p>
          }
          secondaryLabel={flow.label !== null && flow.label.map((label) => (
            <p
              onClick={(e) => secondaryLabel(e, label)}
              className={`sec-label ${label.active === "active" ? "active" : ""}`}
              title={flow.title.title}
            >
              {label.value > 98 ? (
                <CheckmarkOutline className="success" fill="#42be65" />
              ) : (
                <WarningHex className="err" fill="#ff8389" />
              )}
              {label.activityName}
              {/* <span>
                : {label.value}
                <span className="pt">%</span>{" "}
                <Tag
                  className="notify-tag"
                  type={label.value > 98 ? "green" : "red"}
                >
                  {label.value > 98 ? "Healthy" : "Error"}
                </Tag>
              </span> */}
            </p>
          ))}
          complete={flow.progress === "complete"}
          invalid={flow.progress === "error"}
          current={flow.progress === "current"}
        />
      );
    });
  };

  const secondaryLabel = (e, label) => {
    e.stopPropagation();
    updateProcess(label)
    props.secondaryLabel(label);
    // console.log("secondaryLabel ==========", label);
  };

  const onChangeProgress = (e) => {
    e.stopPropagation();
    props.enableTopology(e);
  };

  return (
    <>
      <div className="vs-progressBar">
       <ProgressIndicator
          vertical
          spaceEqually
          onChange={(e) => onChangeProgress(e)}
        >
          {DrawProgress()}
        </ProgressIndicator>
      </div>
    </>
  );
};

export default BizopsProgress;
