import {
  Button,
  ClickableTile,
  Column,
  InlineLoading,
  Link,
  Row,
  SkeletonPlaceholder,
  SkeletonText,
  Tag,
  TextArea,
  Tile,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
  ToggletipLabel
} from "@carbon/react";
import {
  ArrowDown,
  ArrowRight,
  ArrowUp,
  Catalog,
  IbmWatsonDiscovery,
  ThumbsDown,
  ThumbsUp,
  ViewFilled,
  PlayFilledAlt,
  AddAlt
} from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import DataTable from "Carbon-Components/DataTable";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import TooltipCarbon from "Carbon-Components/Tooltip";
import { convertDate, convertUTCDate } from "Common-Modules/ConvertUTCDate";
import {
  getAnyCollection,
  getAssignee,
  getMLSimilarIncident,
  getMSTeams,
  getPNCLogDetails,
  getServiceNowData,
} from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AlertsLog from "./log.json";
import PNCElk from "./pncElk";

const size = 100;

const nodeData = [
  {
    id: "a",
    width: size,
    Height: size,
    color: "#a2191f",
    title: "get /account-types",
  },
  {
    id: "b",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /instant-verification",
  },
  {
    id: "c",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /verify-credentials",
  },
  {
    id: "d",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /verify-otp",
  },
  {
    id: "e",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /verify-pin",
  },
  {
    id: "f",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /verify-secure-id",
  },
  {
    id: "g",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /verify-security-questions",
  },
  {
    id: "h",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /trial-deposits",
  },
  {
    id: "i",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /verify-trial-deposits",
  },
  {
    id: "j",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "post /send-otp",
  },
  {
    id: "k",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "get /user-email",
  },
  {
    id: "l",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "get /bank-details",
  },
  {
    id: "m",
    width: size,
    Height: size,
    color: "#ffb784",
    title: "get /payment-parameters",
  },
];

const linkData = [
  { id: "1", source: "a", target: "b" },
  { id: "2", source: "b", target: "c" },
  { id: "3", source: "b", target: "d" },
  { id: "4", source: "b", target: "e" },
  { id: "5", source: "b", target: "f" },
  { id: "6", source: "b", target: "g" },
  { id: "7", source: "c", target: "h" },
  { id: "8", source: "d", target: "h" },
  { id: "9", source: "e", target: "h" },
  { id: "10", source: "f", target: "h" },
  { id: "11", source: "g", target: "h" },
  { id: "12", source: "h", target: "i" },
  { id: "13", source: "i", target: "j" },
  { id: "14", source: "j", target: "k" },
  { id: "15", source: "k", target: "l" },
  { id: "16", source: "l", target: "m" },
];

const snowHeaderData = [
  {
    key: "number",
    header: "Ticket",
  },
  {
    key: "short_desc",
    header: "Description",
  },
  {
    key: "state",
    header: "Status",
  },
  {
    key: "severity",
    header: "Severity",
  },
  // {
  //   key: "auto_assigned",
  //   header: "Auto Assigned",
  // },
  // {
  //   key: "contact_type",
  //   header: "Type",
  // },
  {
    key: "source",
    header: "Source",
  },
  {
    key: "sys_created_on",
    header: "Created On",
  },
  {
    key: "AI_ASSITANCE",
    header: "AI Assistance",
  },
];

const staticSnowData = [
  {
    id: "1",
    number: "INC9664612",
    config: "TDP-Trips Dispute Processing",
    status: "Open",
    created: "01-02-2024  11:49:20",
    type: "Software/Application/OS",
    short_desc:
      "Failed database connects on Database service dspprod_operations_gf2_rosvc.prod.pncint.net--OPEN--P-",
  },
  {
    id: "2",
    number: "INC9472699",
    config: "TDP-Trips Dispute Processing",
    status: "Open",
    created: "01-02-2024  11:49:20",
    type: "Remote Access",
    short_desc: "#src DSS TRIPS - VMware - Latency Issues",
  },
];

const staticTableHeaders = [
  {
    key: "number",
    header: "Incident ID",
  },
  {
    key: "config",
    header: "Config Item",
  },
  {
    key: "status",
    header: "Status",
  },
  {
    key: "type",
    header: "Type",
  },
  {
    key: "short_desc",
    header: "Description",
  },
];

const appAlertsHeaders = [
  {
    key: "API",
    header: "API",
  },
  {
    key: "Failure Rate",
    header: "Failure Rate",
  },
  {
    key: "Count",
    header: "Count",
  },
  {
    key: "Requests",
    header: "Requests",
  },
  {
    key: "Exception",
    header: "Exception",
  },
];

const IncidentTabs = (props) => {
  const { keycloak } = useKeycloak();
  const [tabTitle, setTabTitle] = useState(0);
  const [snowRowTabData, snowRowDataTable] = useState([]);
  const [mlQuery, setMLQuery] = useState("");
  const [showTabs, setShowTabs] = useState(false);
  const [msTeamsPop, showMSTeamsPop] = useState(false);
  const [selectedTableRow, setSelectTableRow] = useState([]);
  const [showSnowRow, showSnowRowDetails] = useState([]);
  const [alertLogs, setShowAlertsLogs] = useState(false);
  const [logAnalysis, setLogAnalysis] = useState([]);
  const [refresh, refreshIcons] = useState(false);
  const [msTeamslink, setMSTeamslink] = useState({});
  const [switchAnalysis, setSwitchAnalysis] = useState(false);
  const [feedbackPop, showFeedBackPop] = useState("");
  const [assignee, setAssignee] = useState({});
  const [onClickText, setClickText] = useState("");
  const [assigneeGrp, setAssigneeGrp] = useState([]);

  const { data: mlResponse, refetch } = useQuery(
    ["MLsimilar"],
    () => getMLSimilarIncident(keycloak, uuidv4(), mlQuery, "pnc", props.flow),
    {
      retry: 1,
      enabled: false,
    }
  );

  const { data: AssigneeResponse, refetch: assigneeRefetch } = useQuery(
    ["MLAssignee"],
    () => getAssignee(keycloak, crypto.randomUUID(), assignee, "msil"),
    {
      retry: 1,
      cacheTime: 0,
      enabled: false,
    }
  );

  const { data: assignList, assigneeListFetch } = useQuery(
    ["assigneelist"],
    () => getAnyCollection(keycloak, "roster", "soc"),
    {
      retry: 1,
      cacheTime: 0,
       enabled: true,
    }
  );

  const { data: msTeams, refetch: msTeamsFetch } = useQuery(
    ["msteams"],
    async () => await getMSTeams(selectedTableRow, keycloak),
    { retry: 1, enabled: false }
  );

  const { data: logsDetail, refetch: logsDetails } = useQuery(
    ["logDetails"],
    async () => await getPNCLogDetails(keycloak, uuidv4(), "Incident_logs", props.flow),
    { retry: 1 }
  );

  const { data: snowTableData, refetch: snowfetch } = useQuery(
    ["servicenowTab"],
    async () => await getServiceNowData(keycloak, uuidv4(), "pnc", props.flow),
    { retry: 1, enabled: false }
  );

  useEffect(() => {
    if (props.selectedFlow === "static" && logsDetail !== undefined) {
      snowRowDataTable(staticSnowData);
      //  props.snowDataTo(staticSnowData)
    } else if (logsDetail !== undefined) {
      snowfetch();
    }
  }, [logsDetail, props.selectedFlow]);

  useEffect(() => {
    if (
      snowTableData !== undefined &&
      snowTableData.data !== undefined &&
      props.selectedFlow !== "static"
    ) {
      let data = snowTableData.data.serviceNw !== undefined ? snowTableData.data.serviceNw : props.servnow.sort((a, b) => a.state - b.state);

      if(props.toggle === false) {
       data = props.servnow.filter((ser) => ser.simulation !== true)
      }
      snowRowDataTable(data);
      // props.snowDataTo(data)
    }
  }, [snowTableData]);

  useEffect(() => {
    if (mlQuery !== "") {
      refetch();
    }
  }, [mlQuery]);

  useEffect(() => {
    if (msTeams !== undefined) {
      setMSTeamslink(msTeams);
    }
  }, [msTeams]);

  useEffect(() => {
    if (AssigneeResponse !== undefined && AssigneeResponse.data !== undefined) {
      setAssigneeGrp(removeDuplicates(AssigneeResponse.data, "RESOLVED_BY"));
    }
  }, [AssigneeResponse]);

  const removeDuplicates = (array, key) => {
    const seen = new Set();
    return array.filter((item) => {
      const value = item[key];
      return seen.has(value) ? false : seen.add(value);
    });
  };

  useEffect(() => {
    if (assignList !== undefined && assignList.data !== undefined && assignList.data !== "") {
      const assign = assignList.data.roster;
      // console.log("assignList ==============", assignList)
      const filteredGrp = assign.filter((fil) => fil["Group"].includes("MSIL"));
      const filteruniq = filteredGrp.filter((obj, index) => {
        return index === filteredGrp.findIndex((o) => obj.Email === o.Email);
      });
      // console.log("filteredGrp", filteruniq);
      let assigAr = [],
        grp = [];
      filteruniq.forEach((uni) => {
        assigAr.push(uni.Email);
        grp.push(uni.Group.toUpperCase());
      });
      //const filduplicate = [...new Set(grp)];
      // console.log("assigAr", assigAr, grp);
      const query = {
        description:
          "Workernode_Memory_Usage_High_jp_prd -- Host : kube-c301br0t0idd62544p70-cspfjpprdoc-mysql-00000d76.iks.ibm",
        long_description: " ",
        client: "msil",
        assignee: [
          "hmamatha@in.ibm.com",
          "aprakasm@in.ibm.com",
          "avaskum1@in.ibm.com",
          "Lavish.Gupta@ibm.com",
          "swapnali.bhosale@ibm.com",
          "ankitas2@in.ibm.com",
          "radhika.sharma2@ibm.com",
          "surya.mazumder@ibm.com",
        ],
        group: [
          "CitiWealth_IBM_PES",
          "MSIL_GEN2_ROKS_MW JPN L2 TEAM",
          "MSIL_GEN2_ROKS_MW IND L2 TEAM",
        ],
      };
      setAssignee(query);
    }
  }, [assignList]);

  const clickLeftSwitch = (index) => {
    setTabTitle(index);
  };

  // const getMlSimi = (data) => {
  //   return data.map((err) => {
  //     const newEr = Object.entries(err).map(([key, value]) => ({ key, value }));
  //     return (
  //       <ul className="err-log" key={uuidv4()}>
  //         {newEr.map((erlog) => {
  //           return (
  //             <li key={`${erlog.key}-${erlog.value}`}>
  //               <h5>{erlog.key}</h5>
  //               <span>{erlog.value}</span>
  //             </li>
  //           );
  //         })}
  //       </ul>
  //     );
  //   });
  // };

  const getSummary = () => {
    const logs =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "summary")
        : [];
    // console.log("logs", logs);
    return logs.length > 0 ? (
      <>
        <div className="feedback">
          <Button kind="secondary" onClick={() => feedbackBtn("Accept")}>
            {feedbackPop === "Accept" ? (
              "Thank you"
            ) : (
              <>
                Accept Suggestion <ThumbsUp />
              </>
            )}
          </Button>
          <Button kind="tertiary" onClick={() => feedbackBtn("Reject")}>
            Reject suggestion and give feedback <ThumbsDown />
          </Button>
        </div>
        {logs[0]["data"].map((summ) => {
          const newSum = Object.entries(summ).map(([key, value]) => ({
            key,
            value,
          }));
          return (
            <p className="summary" key={uuidv4()}>
              {newSum.map((exe) => (
                <>
                  <h5>{exe.key}</h5>
                  <ul>
                    {exe.value.map((val) => (
                      <li key={val}>{val}</li>
                    ))}
                  </ul>
                </>
              ))}
            </p>
          );
        })}
      </>
    ) : (
      <p>Please enable GENAI capabillity</p>
    );
  };

  const feedbackBtn = (title) => {
    showFeedBackPop(title);
  };

  const getSimilarIn = () => {
    const simi =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "similar ticket")
        : [];
    return simi.length > 0
      ? simi[0]["data"].map((similar) => {
          const newSim = Object.entries(similar).map(([key, value]) => ({
            key,
            value,
          }));
          return (
            <ul className="err-log" key={uuidv4()}>
              {newSim.map((erlog) => {
                return (
                  <li key={`${erlog.key}-${erlog.value}`}>
                    <h5>{erlog.key}</h5>
                    <span>{erlog.value}</span>
                  </li>
                );
              })}
            </ul>
          );
        })
      : getMLSimilarInc();
  };

  const getMLSimilarInc = () => {
    let mlArr = [];
    if (mlResponse !== undefined) {
      mlResponse.data.forEach((ml) => {
        //  const { Resolution, Resolution_category, Close_notes, ...rest } = ml;
        mlArr.push(ml);
      });
    }
    const link = "https://testibmdsous.service-now.com/";
    return mlArr.length > 0 ? (
      <>
        {externalLink(link, "instana")}{" "}
        {mlArr.map((similar) => {
          const newSim = Object.entries(similar).map(([key, value]) => ({
            key,
            value,
          }));
          return (
            <ul className="err-log" key={uuidv4()}>
              {newSim.map((erlog) => {
                return (
                  <li key={`${erlog.key}-${erlog.value}`}>
                    <h5>{erlog.key}</h5>
                    <span>{erlog.value}</span>
                  </li>
                );
              })}
            </ul>
          );
        })}{" "}
      </>
    ) : null;
  };

  const getDetailsAna = () => {
    const root =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "Detailed Analysis")
        : [];
    return root.length > 0
      ? root[0]["data"].map((summ) => {
          const newSum = Object.entries(summ).map(([key, value]) => ({
            key,
            value,
          }));
          return (
            <p className="summary" key={uuidv4()}>
              {newSum.map((exe) => (
                <>
                  <h5>{exe.key}</h5>
                  <ul>
                    {exe.value.map((val) => (
                      <li key={val}>{val}</li>
                    ))}
                  </ul>
                </>
              ))}
            </p>
          );
        })
      : null;
  };

  const getRootCause = () => {
    const root =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "rootcause")
        : [];
    return (
      <p className="summary">
        <h5>Probable Rootcause</h5>
        <ul>
          {root.length > 0 ? (
            root[0]["Probable Rootcause"].map((root) => <li key={root}>{root}</li>)
          ) : (
            <li>{getMLRootCause()}</li>
          )}
        </ul>
      </p>
    );
  };

  const getMLRootCause = () => {
    let mlArr = [];
    if (mlResponse !== undefined) {
      mlResponse.data.forEach((ml) => {
        mlArr.push({ root: ml.Close_notes });
      });
    }
    return mlArr.map((res) => <li key={res.root}>{res.root}</li>);
  };

  const getRecommend = () => {
    // const ana = logAnalysis.length > 0 ? logAnalysis[0].data.filter((data) => data.key === "resolution") : [];
    // console.log("ana", ana)
    // return <p className="summary">
    //     <h5>Recommended Resolution</h5>
    //     <ul>
    //         {ana.length > 0 ? ana[0]["Recommended Resolution"].map((root) => <li>{root}</li>) : <li>{getMLRecommend()}</li>}
    //     </ul>
    // </p>

    const root =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "resolution")
        : [];
    // console.log("root", root);
    return root.length > 0 && root[0].data !== undefined ? (
      root[0]["data"].map((summ) => {
        const newSum = Object.entries(summ).map(([key, value]) => ({
          key,
          value,
        }));
        return (
          <>
          <p className="summary" key={uuidv4()}>
            {newSum.map((exe) => (
              <>
                <h5>{exe.key}</h5>
                <ul>
                  {exe.value.map((val) => (
                    <li key={val}>{val}</li>
                  ))}
                </ul>
              </>
            ))}
          </p>
          </> 
        );
      })
    ) : (
      <>
      <p className="summary">
        <h5>Recommended Resolution</h5>
        <ul>
          {root.length > 0 ? (
            root[0]["Recommended Resolution"].map((root) => <li key={root}>{root}</li>)
          ) : (
            <li>{getMLRecommend()}</li>
          )}
        </ul>
      </p>
      </>
    );
  };

  const getMLRecommend = () => {
    let mlArr = [];
    if (mlResponse !== undefined) {
      mlResponse.data.forEach((ml) => {
        // console.log("ml ============>>>", ml);
        mlArr.push({ recommend: ml["Resolution_category"] });
      });
    }
    return mlArr.map((res) => <li key={res.recommend}>{res.recommend}</li>);
  };

  const getLogs = () => {
    const logs =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "logs")
        : [];
    const link =
      "https://cloud.ibm.com/observe/logging/5d02b2f3-715d-4ab6-97b9-b554df76e601/overview";

    return logs.length > 0 ? (
      <>
        {logs[0]["data"].map((log) => {
          return (
            <p className="summary" key={uuidv4()}>
              <div>{externalLink(link, "LogDNA")}</div>
              <h5>
                Impacted Server:{" "}
                <Tag className="some-class" type="magenta" title="Clear Filter">
                  {log["Impacted Server"]}
                </Tag>
              </h5>

              <div>
                <Tag className="some-class" type="blue" title="Clear Filter">
                  Type: {log["Type"]}
                </Tag>
              </div>

              <span style={{ fontWeight: "600", fontSize: "0.9rem" }}>
                Issue Description
              </span>
              {log["Issue Description"].map((issue) => (
                <p key={issue}>{issue}</p>
              ))}

              <h5>Log Details</h5>
              <ul>
                {log["Log Details"].map((details) => (
                  <li key={details}>{details}</li>
                ))}
              </ul>
            </p>
          );
        })}{" "}
      </>
    ) : (
      <p>No Logs Analysis Tools Configured</p>
    );
  };

  const getMonitorLogs = () => {
    const monitor =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "monitoring alerts")
        : [];
    // console.log("monitor", monitor);
    const link = "https://aiopsri-ibmdso.instana.io/#/home";
    return monitor.length > 0 ? (
      <>
        {externalLink(link, "instana")}{" "}
        {monitor[0]["data"].map((err) => {
          const newEr = Object.entries(err).map(([key, value]) => ({
            key,
            value,
          }));
          return (
            <ul className="err-log" key={uuidv4()}>
              {newEr.map((erlog) => {
                return (
                  <li key={`${erlog.key}-${erlog.value}`}>
                    <h5>{erlog.key}</h5>
                    <span>{erlog.value}</span>
                  </li>
                );
              })}
            </ul>
          );
        })}{" "}
      </>
    ) : (
      <p>No Monitoring Tools Configured</p>
    );
  };

  // const getChangesTicket = () => {
  //   const change =
  //     logAnalysis.length > 0
  //       ? logAnalysis[0].data.filter((data) => data.key === "change ticket")
  //       : [];
  //   // console.log("change", change);
  //   return change.length > 0 ? (
  //     change[0]["data"].map((err) => {
  //       const newEr = Object.entries(err).map(([key, value]) => ({
  //         key,
  //         value,
  //       }));
  //       return (
  //         <ul className="err-log" key={uuidv4()}>
  //           {newEr.map((erlog) => {
  //             return (
  //               <li key={`${erlog.key}-${erlog.value}`}>
  //                 <h5>{erlog.key}</h5>
  //                 <span>{erlog.value}</span>
  //               </li>
  //             );
  //           })}
  //         </ul>
  //       );
  //     })
  //   ) : (
  //     <p>No Change Tickets Found</p>
  //   );
  // };

  const getEvents = () => {
    const events =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "event management")
        : [];
    const link = "https://gearsnoi1.edst.ibm.com:16311/ibm/console/";

    return events.length > 0 ? (
      <>
        {externalLink(link, "NOI")}{" "}
        {events[0]["data"].map((err) => {
          const newEr = Object.entries(err).map(([key, value]) => ({
            key,
            value,
          }));
          return (
            <ul className="err-log" key={uuidv4()}>
              {newEr.map((erlog) => {
                return (
                  <li key={`${erlog.key}-${erlog.value}`}>
                    <h5>{erlog.key}</h5>
                    <span>{erlog.value}</span>
                  </li>
                );
              })}
            </ul>
          );
        })}{" "}
      </>
    ) : (
      <p>No Event Management Tool Configured</p>
    );
  };

  const getSnowRowCellData = (id, data, row) => {
    if (id.includes("number")) {
      return (
        <h6 style={{ textDecoration: "underline", color: "#0f62fe", cursor: "pointer"}} onClick={() => setClickText(`Redirecting to ServiceNow`)}>{data}</h6>
      );
    }
    if (id.includes("state")) {
      return getState(data);
    }
    if (id.includes("severity")) {
      return getPriority(data);
    }
    if (id.includes("sys_created_on")) {
      return data
      //convertDate("dd-mm-yy", convertUTCDate(data), "created"); 
    }
    if (id.includes("source")) {
      return  <h6 style={{ textDecoration: "underline", color: "#0f62fe", cursor: "pointer"}} onClick={() => setClickText(`Redirecting to Splunk`)}>{data}</h6>
    }
    if (id.includes(":description")) {
      try {
        if (data.length <= 50) {
          return data;
        } else {
          let temp = data.substring(0, 50) + "...";
          return (
            <div className="desc-tooltip">
              <TooltipCarbon description={data}>{temp}</TooltipCarbon>
            </div>
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (id.includes(":assignee")) {
      return (
        <div className="toggle-tip">
          <ToggletipLabel>Assignee</ToggletipLabel>
          <Toggletip>
            <ToggletipButton label="Assignee">
              <ViewFilled onClick={() => getAssigneeList()} />
            </ToggletipButton>
            <ToggletipContent>
              {assigneeGrp.length > 0 ? (
                assigneeGrp.map((similar) => {
                  const newSim = Object.entries(similar).map(
                    ([key, value]) => ({ key, value })
                  );
                  return (
                    <ul className="owner-grp" key={uuidv4()}>
                      {newSim.map((erlog) => {
                        return erlog.key === "RESOLVED_BY" ? (
                          <li key={`${erlog.key}-${erlog.value}`}>
                            <h5>{erlog.key}</h5>
                            <span>{erlog.value}</span>
                          </li>
                        ) : null;
                      })}
                    </ul>
                  );
                })
              ) : (
                <ul className="owner-grp" style={{ width: "200px" }}>
                  <li>
                    <SkeletonText />
                  </li>
                  <li>
                    <SkeletonText />
                  </li>
                </ul>
              )}
            </ToggletipContent>
          </Toggletip>
        </div>
      );
    }

    if(id.includes("AI_ASSITANCE")) {
      return <ul className="action-icons">
      <li
        className="enable"
        title="Analyze"
        onClick={() => onclickAnalize(row)}
      >
        <IbmWatsonDiscovery />
      </li> </ul>
    }

    return data;
  };

  const getAssigneeList = () => {
    if (Object.entries(assignee).length > 0) {
      assigneeRefetch();
    }
  };

  const getState = (num) => {
    switch (num) {
      case "1":
        return "New";
      case "2":
        return "In Progress";
      case "3":
        return "On Hold";
      case "6":
        return "Resolved";
      default:
        return "null";
    }
  };

  const getPriority = (num) => {
    switch (num) {
      case "1":
        return (
          <div>
            P1 - Critical{" "}
            <ArrowUp size={12} stroke="#da1e28" strokeWidth="3px" />
          </div>
        );
      case "2":
        return (
          <div>
            P2 - High <ArrowUp size={12} stroke="#fa4d56" strokeWidth="3px" />
          </div>
        );
      case "3":
        return (
          <div>
            P3 - Medium{" "}
            <ArrowDown size={12} stroke="#ff8389" strokeWidth="3px" />
          </div>
        );
      case "4":
        return (
          <div>
            P4 - Low <ArrowDown size={12} stroke="#d2a106" strokeWidth="3px" />
          </div>
        );
      case "5":
        return (
          <div>
            P5 - Very Low{" "}
            <ArrowDown size={12} stroke="#d2a106" strokeWidth="3px" />
          </div>
        );
      default:
        return "null";
    }
  };

  const getSnowActions = (row) => {
    return (
      <ul className="action-icons">
        <li
            className="enable"
            title="Create Ticket"
            onClick={() => setClickText(`Redirecting to ServiceNow`)}
        >
          <AddAlt />
        </li>
        <li
          className="enable"
          title="Trigger RunBook"
          onClick={() => setClickText(`Redirecting to RunBook`)}
        >
         <PlayFilledAlt />
        </li>
        <li className="enable" onClick={() => setClickText(`Redirecting to Slack`)}>
                    {/* <a
                        href="https://ibm-test.enterprise.slack.com/archives/C081XC8KZU4"
                        target="_blank"
                        rel="noopener noreferrer"
                    > */}
                    <img
                            alt="slack"
                            src={
                                require(`../../../../Assets/External/slack.svg`)
                                    .default
                            }
                            width={16}
                            height={16}
                    />
                    {/* </a> */}
                </li>
        <li className="enable" onClick={() => onClickTeams(row)}>
          <img
            src={require(`../../../../Assets/External/ms-teams.svg`).default}
            alt="ms-teams"
            className="ms-teams"
            width={20}
            height={20}
            style={{ cursor: "pointer" }}
          />
        </li>
      </ul>
    );
  };

  const onclickAnalize = (row) => {
    const tableRow = getRowActions(row);
    const res = snowRowTabData.filter(
      (inc) => inc.number === tableRow[0].number
    );

    // setSnowIncident(res[0].data)
    // const similarInc = incidentData.filter((inc) => inc.key === tableRow[0].number)
    // console.log("similarInc", similarInc)
    // if (similarInc.length > 0) {
    //     setSnowIncident(similarInc[0].data)
    // }

    if (res[0].short_desc !== undefined) {
      const subString = res[0].short_desc.split("-")[0];
      // console.log("res[0].short_desc", res[0].short_desc);
      setMLQuery(subString);
    }

    setSelectTableRow(tableRow);
    setShowTabs(true);
    filterLogsDetails(res);
    setSwitchAnalysis(true);
    setTimeout(() => {
      setSwitchAnalysis(false);
    }, 2000);
    const ele = document.getElementById(`row6`);
    ele.scrollIntoView({ behavior: "smooth" });
  };

  const onClickTeams = (row) => {
    const tableRow = getRowActions(row);
    setSelectTableRow(tableRow);
    showMSTeamsPop(true);
    setMSTeamslink();
    setTimeout(() => {
      msTeamsFetch();
    }, 1000);
  };

  const filterLogsDetails = (res) => {

    console.log("logsDetail =======", logsDetail)
    const response = logsDetail.data[0] !== undefined ? logsDetail.data[0].data: logsDetail.data.data.ato_genai_response[0].data;
    let newAr = [];
    if (res[0].short_desc !== undefined) {
      response.forEach((log) => {
        if (res[0].short_desc.includes(log.desc)) {
          newAr.push(log);
        }
      });
    } else {
      newAr.push(response[0]);
    }
    setLogAnalysis(newAr);
  };

  // const onClickDetails = (row) => {
  //   const tableRow = getRowActions(row);
  //   const res = snowRowTabData.filter(
  //     (inc) => inc.number === tableRow[0].number
  //   );
  //   showSnowRowDetails(res);
  // };

  function getRowActions(row) {
    let tableRow = [];
    row.cells.forEach((cell) => {
      snowRowTabData.forEach((rows) => {
        if (cell.id.includes("number")) {
          if (cell.value === rows.number) {
            tableRow.push(rows);
          }
        }
      });
    });
    return tableRow;
  }

  const SelectedSnowRow = () => {
    // console.log("showSnowRow", showSnowRow);
    return showSnowRow.map((err) => {
      const newEr = Object.entries(err).map(([key, value]) => ({ key, value }));
      return (
        <ul className="err-log" key={uuidv4()}>
          {newEr.map((erlog) => {
            return erlog.key !== "id" ? (
              <li key={`${erlog.key}-${erlog.value}`}>
                <h5>{erlog.key}</h5>
                <span>{erlog.value}</span>
              </li>
            ) : null;
          })}
        </ul>
      );
    });
  };

  const closepopup = () => {
    showSnowRowDetails([]);
    setShowAlertsLogs(false);
    showMSTeamsPop(false);
    showFeedBackPop("");
  };

  const getappAlertCell = (id, data) => {
    if (id.includes("severity")) {
      return getPriority(data);
    }
    if (id.includes("state")) {
      return getState(data);
    }
    return data;
  };

  const getAlertsActions = (row) => {
    return (
      <ul className="action-icons">
        <li
          className="enable"
          title="Analyze"
          onClick={() => onclickAnalize(row)}
        >
          <IbmWatsonDiscovery />
        </li>
        <li
          className="enable"
          title="Logs"
          //   onClick={() => setShowAlertsLogs(true)}
        >
          <Catalog />
        </li>
      </ul>
    );
  };

  const getAlertLogs = () => {
    return AlertsLog.map((err) => {
      const newEr = Object.entries(err).map(([key, value]) => ({ key, value }));
      return (
        <ul className="err-log" key={uuidv4()}>
          {newEr.map((erlog) => {
            return (
              <li key={`${erlog.key}-${erlog.value}`}>
                <h5>{erlog.key}</h5>
                <span>{erlog.value}</span>
              </li>
            );
          })}
        </ul>
      );
    });
  };

  const getSkeleton = () => {
    return (
      <>
        <SkeletonText />
        <SkeletonText />
        <SkeletonText />
        <SkeletonPlaceholder />
      </>
    );
  };

  const onRefreshSnow = () => {
    refreshIcons(true);
    snowfetch();
    setShowTabs(false);
    snowRowDataTable([]);
  };

  const getTablesummary = () => {
    // console.log("snowRowTabData", snowRowTabData);
    const newAr = [
      // {
      //     key: "User Reported",
      //     count: countValueOcc(snowRowTabData, "User Reported", "contact_type")
      // },
      // {
      //     key: "System Generated",
      //     count: countValueOcc(snowRowTabData, "Instana", "sys_created_by")
      // },
      {
        key: "New",
        count: countValueOcc(snowRowTabData, "1", "state"),
      },
      {
        key: "Critical",
        count: countValueOcc(snowRowTabData, "1", "severity"),
      },
      {
        key: "In Progress",
        count: countValueOcc(snowRowTabData, "2", "state"),
      },
      {
        key: "Bot Assigned",
        count: snowRowTabData.filter((fil) => fil.auto_assigned === "YES").length
      },
    ];
    return snowRowTabData.length > 0
      ? newAr.map((row) => {
          return (
            <ClickableTile
              onClick={() => clicksnowtiles(row)}
              className="table-tiles"
              key={row.key}
            >
              <span>{row.count}</span>
              <h5>{row.key}</h5>
            </ClickableTile>
          );
        })
      : [1, 2, 3, 4, 5].map(() => (
          <ClickableTile className="table-tiles" key={uuidv4()}>
            <SkeletonPlaceholder />
          </ClickableTile>
        ));
  };

  const clicksnowtiles = (e) => {
    // console.log("eeee", e);
  };

  function countValueOcc(array, value, field) {
    // Use reduce to iterate over the array
    return array.reduce((count, obj) => {
      // Increment count if object's value matches the specified value
      return obj[field] === value ? count + 1 : count;
    }, 0); // Initialize count to 0
  }

  function checkIncidentBoxLength() {
    let leng = 0;

    if (props.appFlow === "Incident Analysis") {
      leng = 1;
    } else {
      props.boxData.forEach((box) => {
        if (box.name === "Incident") {
          leng = box.count;
        }
      });
    }
    return leng === 0 ? false : true;
  }

  const redirectToIne = (node) => {
    // console.log("node ===", node);
    const newObj = [{ number: node.title }];
    setSelectTableRow(newObj);
    setShowTabs(true);
    const ele = document.getElementById(`row6`);
    ele.scrollIntoView({ behavior: "smooth" });
  };

  const getRelatedChanges = () => {
    const simi =
      logAnalysis.length > 0
        ? logAnalysis[0].data.filter((data) => data.key === "related changes")
        : [];
    return simi.length > 0
      ? simi[0]["data"].map((similar) => {
          const newSim = Object.entries(similar).map(([key, value]) => ({
            key,
            value,
          }));
          return (
            <ul className="err-log" key={uuidv4()}>
              {newSim.map((erlog) => {
                return (
                  <li key={`${erlog.key}-${erlog.value}`}>
                    <h5>{erlog.key}</h5>
                    <span>{erlog.value}</span>
                  </li>
                );
              })}
            </ul>
          );
        })
      : "No Related Changes Available";
  };

  const getsumRef = () => {
    return (
      <div className="summary">
        <h5>Summary</h5>
        <ul>
          <li>
            The Infra logs are related to the
            wba-ma-wire-transfers-landing-single application in the wba-prod
            namespace.
          </li>
          <li>
            The Infra logs occurred on 16/Feb/2024 at 13:51:17 (UTC time) and
            were related to the wba-ma-wire-transfers-landing-ui container.
          </li>
          <li>
            The Infra logs involved GET requests to the
            /wba-ma-wire-transfers-landing-ui endpoint.
          </li>
          <li>
            The Infra logs resulted in different HTTP response codes: 200, 301,
            and 301.
          </li>
          <li>The Infra logs originated from the IP address 10.168.30.1.</li>
          <li>The Infra logs were recorded in the stdout stream.</li>
        </ul>
      </div>
    );
  };

  const getroot = () => {
    return (
      <div className="summary">
        <h5>Root Cause Analysis:</h5>
        <ul>
          <li>
            The different HTTP response codes suggest that there might be an
            issue with the routing or redirection of requests to the correct
            endpoint.
          </li>
          <li>
            The fact that the Infra logs occurred at the same time and involved
            the same application, container, and endpoint suggests that there
            might be a problem with the application code or configuration.
          </li>
          <li>
            The fact that the Infra logs originated from the same IP address
            suggests that there might be an issue with the network or firewall
            settings.
          </li>
          <li>
            The fact that the Infra logs were recorded in the stdout stream
            suggests that there might be an issue with the logging configuration
            or settings.
          </li>
        </ul>
      </div>
    );
  };

  const getRecom = () => {
    return (
      <div className="summary">
        <h5>Recommendations:</h5>
        <ul>
          <li>
            Review the application code and configuration to ensure that
            requests are being routed and redirected correctly.
          </li>
          <li>
            Check the network and firewall settings to ensure that traffic is
            allowed from the IP address 10.168.30.1.
          </li>
          <li>
            Review the logging configuration and settings to ensure that logs
            are being recorded correctly and in a consistent format.
          </li>
          <li>
            Consider implementing additional monitoring and alerting to detect
            similar incidents in the future.
          </li>
          <li>
            Test the application thoroughly to ensure that it is functioning as
            expected
          </li>
        </ul>
      </div>
    );
  };

  const externalLink = (link, name) => {
    return (
      <div className="link-external">
        <Link
          href={link}
          target="_blank"
          renderIcon={() => <ArrowRight aria-label="Arrow Right" />}
        >
          Details
        </Link>
      </div>
    );
  };

  const getMsTeamsLink = () => {
    const split = msTeams.data["link"].split(" ");
    return split[6] !== "undefined" ? (
      <h5>
        Channel got created with link as{" "}
        <Link href={split[6]} target="_blank" rel="noopener noreferrer">
          Microsoft Teams
        </Link>
      </h5>
    ) : (
      <h5>
        Channel got created with link as{" "}
        <Link href={fetchMsChannel()} target="_blank" rel="noopener noreferrer">
          Microsoft Teams
        </Link>
      </h5>
    );
  };

  const fetchMsChannel = () => {
    let channel = "";
    const msteamlink = msTeams.data["response"];

    if (msteamlink !== undefined && msteamlink["value"].length > 0) {
      const row = selectedTableRow[0].number;
      msteamlink["value"].forEach((val) => {
        console.log(
          "dfewqfioe",
          val["displayName"] === `pnc_${row.toLowerCase()}`
        );
        if (val["displayName"] === `pnc_${row.toLowerCase()}`) {
          channel = val["webUrl"];
        }
      });
    }
    return channel;
  };

  console.log(
    "props.clickedTiles",
    checkIncidentBoxLength(),
    props,
    props.simulationMode,
    selectedTableRow
  );

  console.log("snowRowTabData", snowRowTabData)

  return checkIncidentBoxLength() ? (
    <>
      <Row id="row4">
        <Column lg={16} md={8} sm={4} className="paddingRgt paddingLft">
          {props.clickedTiles === "Incidents" ? (
            <>
              {props.selectedFlow !== "static" ? getTablesummary() : null}
              <DataTable
                rowData={snowRowTabData}
                headerData={
                  props.selectedFlow === "static"
                    ? staticTableHeaders
                    : snowHeaderData
                }
                title=""
                getRowCellData={getSnowRowCellData}
                getTableHeader={(header) => header}
                table_particular_actions={true}
                actionsNeeded={false}
                getActions={getSnowActions}
                iconRotate={refresh}
                onRefresh={onRefreshSnow}
                export={true}
                setting={true}
              ></DataTable>{" "}
            </>
          ) : props.clickedTiles === "Application Alerts" ? (
            <DataTable
              rowData={props.appAlerts}
              headerData={appAlertsHeaders}
              title=""
              getRowCellData={getappAlertCell}
              getTableHeader={(header) => header}
              table_particular_actions={true}
              actionsNeeded={false}
              getActions={getAlertsActions}
            ></DataTable>
          ) : props.simulationMode === true && props.selectedService !== "" ? (
            // <PNCTopologyGraph redirectToIne={redirectToIne} selectedService={props.selectedService} />
            <PNCElk
              nodes={nodeData}
              links={linkData}
              layout="layered"
              nodeClick={redirectToIne}
              selectedService={props.selectedService}
            />
          ) : null}
        </Column>
      </Row>
      <Row id="row5">
        <br />
      </Row>
      <Row id="row6" style={{ height: "500px" }}>
        {showTabs ? (
          <Column lg={16} md={8} sm={4} className="paddingRgt paddingLft">
            <div className="verticaltabs">
              <div className="left-switch">
                <div style={{ height: "600px", backgroundColor: "#dedede" }}>
                  {selectedTableRow.length > 0 ? (
                    <div className="ticket">{selectedTableRow[0].number}</div>
                  ) : null}
                  <div className="lft-title">
                    <h5>Inference</h5>
                  </div>
                  <ClickableTile
                    className={tabTitle === 0 ? "active" : ""}
                    onClick={() => clickLeftSwitch(0)}
                  >
                    <h6>Summary</h6>
                  </ClickableTile>
                  <ClickableTile
                    disabled={props.simulationMode}
                    className={tabTitle === 3 ? "active" : ""}
                    onClick={() => clickLeftSwitch(3)}
                  >
                    <h6>Detailed Analysis</h6>
                  </ClickableTile>
                  <ClickableTile
                    className={tabTitle === 1 ? "active" : ""}
                    onClick={() => clickLeftSwitch(1)}
                  >
                    <h6>Probable Root Cause</h6>
                  </ClickableTile>
                  <ClickableTile
                    className={tabTitle === 2 ? "active" : ""}
                    onClick={() => clickLeftSwitch(2)}
                  >
                    <h6>Recommended Resolution</h6>
                  </ClickableTile>

                  {/* <ClickableTile className={tabTitle === 1 ? "active" : ""} onClick={() => clickLeftSwitch(1)}><h6>Similar Incident</h6></ClickableTile>
                                <ClickableTile className={tabTitle === 2 ? "active" : ""} onClick={() => clickLeftSwitch(2)}><h6>Probable Cause</h6></ClickableTile>
                                <ClickableTile className={tabTitle === 3 ? "active" : ""} onClick={() => clickLeftSwitch(3)}><h6>Recommended Resolution</h6></ClickableTile> */}
                  {/* <ClickableTile disabled ><h6>Topology</h6></ClickableTile> */}
                  {!props.simulationMode ? (
                    <div className="lft-title">
                      <h5>Analysis</h5>
                    </div>
                  ) : null}
                  {!props.simulationMode ? (
                    <ClickableTile
                      disabled={props.simulationMode}
                      className={tabTitle === 7 ? "active" : ""}
                      onClick={() => clickLeftSwitch(7)}
                    >
                      <h6> Event Management</h6>
                    </ClickableTile>
                  ) : null}
                  {!props.simulationMode ? (
                    <ClickableTile
                      disabled={props.simulationMode}
                      className={tabTitle === 8 ? "active" : ""}
                      onClick={() => clickLeftSwitch(8)}
                    >
                      <h6> Related Changes</h6>
                    </ClickableTile>
                  ) : null}
                  {!props.simulationMode ? (
                    <ClickableTile
                      disabled={props.simulationMode}
                      className={tabTitle === 6 ? "active" : ""}
                      onClick={() => clickLeftSwitch(6)}
                    >
                      <h6> Similar Incidents</h6>
                    </ClickableTile>
                  ) : null}
                  {!props.simulationMode ? (
                    <ClickableTile
                      disabled={props.simulationMode}
                      className={tabTitle === 4 ? "active" : ""}
                      onClick={() => clickLeftSwitch(4)}
                    >
                      <h6> Log Analysis</h6>
                    </ClickableTile>
                  ) : null}
                  {!props.simulationMode ? (
                    <ClickableTile
                      disabled={props.simulationMode}
                      className={tabTitle === 5 ? "active" : ""}
                      onClick={() => clickLeftSwitch(5)}
                    >
                      <h6> Monitoring Alerts</h6>
                    </ClickableTile>
                  ) : null}
                  {/* <ClickableTile disabled className={tabTitle === 6 ? "active" : ""} onClick={() => clickLeftSwitch(6)}><h6> Change Tickets</h6></ClickableTile>
                                <ClickableTile disabled className={tabTitle === 8 ? "active" : ""} onClick={() => clickLeftSwitch(8)}><h6> Related Open Ticket</h6></ClickableTile> */}
                </div>
              </div>
              {/* <div className="rgt-switch" style={{ width: "84%" }}>
                            <ClickableTile className={tabTitle === 0 ? "active" : ""} onClick={() => clickLeftSwitch(0)}><h6>Summary</h6></ClickableTile>
                            <ClickableTile className={tabTitle === 1 ? "active" : ""} onClick={() => clickLeftSwitch(1)}><h6>Similar Incident</h6></ClickableTile>
                            <ClickableTile className={tabTitle === 2 ? "active" : ""} onClick={() => clickLeftSwitch(2)}><h6>Probable Cause</h6></ClickableTile>
                            <ClickableTile className={tabTitle === 3 ? "active" : ""} onClick={() => clickLeftSwitch(3)}><h6>Recommended Resolution</h6></ClickableTile>
                            <ClickableTile disabled ><h6>Topology</h6></ClickableTile>
                        </div> */}
              <Tile className="tile-switch">
                {tabTitle === 0
                  ? !switchAnalysis
                    ? !props.simulationMode
                      ? getSummary()
                      : getsumRef()
                    : getSkeleton()
                  : null}
                {tabTitle === 3 ? (
                  !switchAnalysis ? (
                    <> {getDetailsAna()}</>
                  ) : (
                    getSkeleton()
                  )
                ) : null}
                {tabTitle === 1
                  ? !switchAnalysis
                    ? !props.simulationMode
                      ? getRootCause()
                      : getroot()
                    : getSkeleton()
                  : null}
                {tabTitle === 2
                  ? !switchAnalysis
                    ? !props.simulationMode
                      ? 
                      <>
                                          <div className="feedback">
          <Button kind="secondary" onClick={() => feedbackBtn("Accept")}>
            {feedbackPop === "Accept" ? (
              "Thank you"
            ) : (
              <>
                Accept Recommendation <ThumbsUp />
              </>
            )}
          </Button>
          <Button kind="tertiary" onClick={() => feedbackBtn("Reject")}>
            Reject suggestion and give feedback <ThumbsDown />
          </Button>
        </div>
        {getRecommend()}
                      </>
                      : getRecom()
                    : getSkeleton()
                  : null}
                {tabTitle === 4
                  ? !switchAnalysis
                    ? getLogs()
                    : getSkeleton()
                  : null}
                {tabTitle === 5
                  ? !switchAnalysis
                    ? getMonitorLogs()
                    : getSkeleton()
                  : null}
                {tabTitle === 6
                  ? !switchAnalysis
                    ? getSimilarIn()
                    : getSkeleton()
                  : null}
                {tabTitle === 7
                  ? !switchAnalysis
                    ? getEvents()
                    : getSkeleton()
                  : null}
                {tabTitle === 8 ? getRelatedChanges() : null}
              </Tile>
            </div>
          </Column>
        ) : null}

        {showSnowRow.length > 0 ? (
          <PassiveModal
            heading="Details"
            size="lg"
            canCloseDetailsPopup={() => closepopup()}
          >
            <div>{SelectedSnowRow()}</div>
          </PassiveModal>
        ) : null}

        {alertLogs ? (
          <PassiveModal
            heading="Logs"
            size="lg"
            canCloseDetailsPopup={() => closepopup()}
          >
            <div>{getAlertLogs()}</div>
          </PassiveModal>
        ) : null}

        {msTeamsPop ? (
          <PassiveModal
            heading={
              selectedTableRow.length > 0 ? selectedTableRow[0]["number"] : ""
            }
            size="sm"
            canCloseDetailsPopup={() => closepopup()}
          >
            <div>
              {msTeamslink !== undefined && msTeamslink.data !== undefined ? (
                <> {getMsTeamsLink()} </>
              ) : (
                <InlineLoading
                  status="active"
                  iconDescription="Loading"
                  description="Fetching Channel..."
                />
              )}
            </div>
          </PassiveModal>
        ) : null}

        {feedbackPop === "Reject" ? (
          <PassiveModal
            heading="Feedback"
            size="sm"
            canCloseDetailsPopup={() => closepopup()}
          >
            <div>
              <TextArea
                labelText="Give Feedback"
                helperText=""
                rows={4}
                id="text-area-1"
              />
              <br />
              <Button kind="tertiary">Submit</Button>
            </div>
          </PassiveModal>
        ) : null}

        {onClickText !== "" ?
          <PassiveModal
            heading=""
            size="sm"
            canCloseDetailsPopup={() => setClickText("")}
          >
            <div>
              {onClickText} URL...
              <br />
              <br />
              <br />
              <Button style={{ float: "right" }} onClick={() => setClickText("")}  kind="tertiary">Ok</Button>
            </div>
          </PassiveModal> : null}
      </Row>
    </>
  ) : null;
};

export default IncidentTabs;
