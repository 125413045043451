/** @format */
import { Content, ErrorBoundary } from "@carbon/react";
import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
// Header
import AppHeader from "./Components/AppHeader/AppHeaderNew";
// HomePage
import Dashboard from "Components/HomePage/HomeDashboard/DashboardNew";
// Insights Hub
import Wizard from "Components/ICTE/Insights/Wizard";
// Instrumentation Hub
import InstrumentationComponent from "Components/InstrumentationHub/Index";
// Data Hub
import DataHubComponent from "Components/DataCore/Index";
// Operations Hub
import TraceSummary from "Components/ICPSA/AppPerformanceAvailabililty/TraceSummary";
import ITOperations from "Components/ICTE/IntegratedOperations/operaionIndex";
import ITGovernOperations from "Components/ICTE/IntegratedOperations/index";
import ClusterIssuesWrapper from "Components/ICTE/SecurityOps/ContainerSecurity/ClusterIssuesWrapper";
// ValueStream
import BankingWorkflowDetails from "Components/ICTE/ValueStream/Bank/vsLastPage";
import BankingWorkflow from "Components/ICTE/ValueStream/Bank/vsWorkflowView";

import ValueStream from "Components/ICTE/ValueStream/valueStreamIndex";
import ValueStreamWorkflowDetail from "Components/ICTE/ValueStream/valueStreamLastPage";
import ValueStreamWorkflow from "Components/ICTE/ValueStream/valueStreamWorkflowView";

import WorkflowDetails from "Components/ICTE/ValueStream/customerJourney/WorkflowDetail";
import { default as PWorkflowInference } from "Components/ICTE/ValueStream/customerJourney/g02Application";
import PWorkFlowIndex from "Components/ICTE/ValueStream/customerJourney/index";

// IntelligentALM
import IntelligentALM from "Components/ICTE/IntelligentALM/BuildDetails/Overview";
import SecurityOps from "Components/ICTE/SecurityOps";
// Susops
import NotFound from "Common-Modules/NotFound";
import Page401 from "Common-Modules/Page401";
import Page500 from "Common-Modules/Page500";
import PerformanceAvailabilityComponent from "Components/ICPSA/AppPerformanceAvailabililty/PerformanceAvailabilityComponent";
import TopologyGraphComponent from "Components/ICPSA/AppPerformanceAvailabililty/TopologyGraphComponent";
import AutoPatcher from "Components/ICPSA/AutoPatcher/AutoPatcher";
import EventBridge from "Components/ICPSA/EventBridge/EventBridge";
import SusOpsDashboard from "Components/ICTE/SusOps/Dashboard";
//Job observabillity
import DoIndex from "Components/DO";
import DeploymentInsights from "Components/ICPSA/DeploymentInsights/DeploymentInsights";
import PipelineUsage from "Components/ICPSA/PipelineUsage/PipelineUsage";
import PlatformAlerts from "Components/ICPSA/PlatformInsights/PlatformAlerts";
import PlatformInsights from "Components/ICPSA/PlatformInsights/PlatformInsights";
import PlatformMetrics from "Components/ICPSA/PlatformInsights/PlatformMetrics";
import VersionControl from "Components/ICPSA/VersionControl/VersionControl";
import WorkflowViewDetailsIndex from "Components/ICTE/JobObservability/JobWorkflowDetailsIndex";
import WorkflowViewIndex from "Components/ICTE/JobObservability/JobWorkflowIndex";
import WorkflowViewDetailsIndex1 from "Components/ICTE/JobObservability1/JobWorkflowDetailsIndex";
import WorkflowViewIndex1 from "Components/ICTE/JobObservability1/JobWorkflowIndex";
// Mainframe
import WorkflowViewMainframe from "Components/ICTE/JobObservabilityMainFrame/JobWorkflowIndex";
import WorkflowViewMainframeDetails from "Components/ICTE/JobObservabilityMainFrame/JobWorkflowDetailsIndex";
// Kenvue
import KenvueWorkflowDetailsIndex from "Components/ICTE/Kenvue/KenvueWorkflowDetailsIndex";
import KenvueWorkflowViewIndex from "Components/ICTE/Kenvue/KenvueWorkflowIndex";
import KenvueIndex from "Components/ICTE/Kenvue/kenvue";
// Nerve
import GitHubOrganizations from "Components/ICPSA/GitHub/GitHubOrganizations";
import GitHubRepoDetails from "Components/ICPSA/GitHub/GitHubRepoDetails";
import GitHubRepos from "Components/ICPSA/GitHub/GitHubRepos";
import NerveTileInfo from "Components/ICTE/AdaniNerve/Dashboard";
import NerveDetails from "Components/ICTE/AdaniNerve/appLastPAge";
import NerveCenter from "Components/ICTE/AdaniNerve/index";
// cpg
import TraceDetailsDashboard from "Components/ICTE/AdaniNerve/TraceDetailsDashboard";
import TelcoDashboard from "Components/ICTE/Telco";
import CpgDetails from "Components/ICTE/ValueStream/Cpg/CpgDetails";
import CpgWorkflow from "Components/ICTE/ValueStream/Cpg/CpgWorkflowFlow";
import CpgWorkflowView from "Components/ICTE/ValueStream/Cpg/workflowView";
// citi
import AdminPanel from "Components/Admin Panel/AdminPanel";
import NoPermission from "Components/Common/NoPermission";
import AssetDetailsIndex from "Components/ICTE/Citi/AssetDetailsIndex";
import ClusterWiseDetailsIndex from "Components/ICTE/Citi/ClusterWiseDetailsIndex";
// Process min
import PmProcess from "Components/ICTE/ValueStream/ProcessMining/PmProcess";
import pmSubDomains from "Components/ICTE/ValueStream/ProcessMining/PmSubdomain";

import DevandOpsInsights from "Components/ICTE/ALMDashboard/DevandOpsInsights";
import AtoBusiness from "Components/ICTE/ValueStream/ATO/AtoBusiness";
import ATODashboard from "Components/ICTE/ValueStream/ATO/AtoDashboard";
import AtoDetails from "Components/ICTE/ValueStream/ATO/AtoDetails";
import NorkfolkDashboard from "Components/ICTE/ValueStream/Norfolk/NorkfolkDashboard";
import TroubleshootView from "Components/DO/TroubleShootView/TroubleshootView";



const Routes = () => {
    const [isFetchingPermissions] = useState(false);

    return (
        <>
            <ErrorBoundary fallback={<h3>Error while loading header</h3>}>
                {!isFetchingPermissions && (
                    <AppHeader roles={["productAdmin", "Guest"]} />
                )}
            </ErrorBoundary>
            <Content
                id={isFetchingPermissions ? "" : "controltower"}
                className="contentDisplay"
            >
                <>
                    <Switch>
                        <Route path="/404" component={NotFound} />
                        <Route path="/401" component={Page401} />
                        <Route path="/500" component={Page500} />

                        <Route path="/unauth" component={NoPermission} />
                        <Route path="/adminPanel" component={AdminPanel} />
                        
                        {/* --------------- Value Stream -------------*/}
                        <Route path="/valueStream" component={ValueStream} />
                        <Route path="/workflowView/:workflow" component={BankingWorkflow} />
                        <Route path="/workflowViewDetails/:workflow" component={BankingWorkflowDetails} />

                        <Route path="/VsworkflowView/:workflow" component={ValueStreamWorkflow} />
                        <Route path="/VsworkflowViewDetails/:workflow" component={ValueStreamWorkflowDetail} />

                        <Route path="/workFlowJourney" component={WorkflowDetails} />
                        <Route path="/CustomerJourney" component={PWorkFlowIndex} />
                        <Route path="/WorkflowJoruneyInference" component={PWorkflowInference} />

                        <Route path="/cpgWorkflow" component={CpgWorkflow} />
                        <Route path="/cpgDetails" component={CpgDetails} />
                        <Route path="/workflowViewCpg/:workflow" component={CpgWorkflowView} />

                        <Route path="/pmProcess" component={PmProcess} />
                        <Route path="/pmSubDomains" component={pmSubDomains} />
                        

                        <Route path="/almDashboard" component={DevandOpsInsights} />

                        {/* ------------- DO ------------- */}
                        <Route path="/troubleShootView" component={TroubleshootView} />

                        {/* ------------- ATO ------------ */ }
                        <Route path="/atoDashboard/:name" component={ATODashboard} />
                        <Route path="/atoDetails" component={AtoDetails} />
                        <Route path="/atoBusiness/:name" component={AtoBusiness} />

                        <Route path="/norfolkDashboard" component={NorkfolkDashboard} />
                        
                        {/* ------------- Nerve ---------- */}
                        <Route path="/nerveDetails" component={NerveDetails} />
                        <Route path="/nerveTileInfo" component={NerveTileInfo} />
                        <Route path="/nerveCenter" component={NerveCenter} />
                        <Route path="/nerveTileInfoDetails" component={TraceDetailsDashboard} />

                        {/* ---------- Citi ----------- */}
                        <Route path="/citiDetails/:cluster" component={AssetDetailsIndex} />
                        <Route path="/citiCluster/:details" component={ClusterWiseDetailsIndex} />

                        {/* -------------- Kenvue --------------*/}
                        <Route path="/Kenvue" component={KenvueIndex} />
                        <Route path="/KenvueWorkflowIndex" component={KenvueWorkflowViewIndex} />
                        <Route path="/KenvueWorkflowDetailsIndex" component={KenvueWorkflowDetailsIndex} />

                        {/* --------------- Hub -------------- */}
                        <Route path="/itGovernance" component={Wizard} />
                        <Route path="/itGovernanceOps" component={ITGovernOperations} />

                        {/* ---------------- Instrumentation HUB ---------------- */}
                        <Route path="/InstrumentationHub" component={InstrumentationComponent} />

                        {/* ------------- Data Core --------------------*/}
                        <Route path="/DataHub" component={DataHubComponent} />

                        {/* ---------------- Sustainability HUB ---------------- */}
                        <Route path="/susOpsDashboard" component={SusOpsDashboard} />
                        {/* <Route path="/calculator" component={CalcContainer} />
                        <Route path="/susopsInstrumentation" component={CloudDetails} />
                        <Route path="/susopsManual" component={CloudDetails} />
                        <Route path="/susopsEDA" component={SusopsEDA} /> */}
                        {/* ---------------- Job Observability ---------------- */}
                        <Route path="/WorkflowIndex" component={WorkflowViewIndex} />
                        <Route path="/WorkflowIndex1" component={WorkflowViewIndex1} />
                        <Route path="/WorkflowDetailsIndex" component={WorkflowViewDetailsIndex} />
                        <Route path="/WorkflowDetailsIndex1" component={WorkflowViewDetailsIndex1} />

                        {/* ---------------- Mainframe ---------------- */}
                        <Route path="/WorkflowDetailsIndex2" component={WorkflowViewMainframeDetails} />
                        <Route path="/WorkflowIndex2" component={WorkflowViewMainframe} />

                        {/* ---------------- Operations Hub ----------------- */}
                        <Route path="/performanceAvailability" component={PerformanceAvailabilityComponent} />
                        <Route path="/topologyGraph" component={TopologyGraphComponent} />
                        <Route path="/traceSummary" component={TraceSummary} />
                        <Route path="/itOperations" component={ITOperations} />
                        <Route path="/iALM" component={IntelligentALM} />
                        <Route path="/securityOps" component={SecurityOps} />
                        <Route path="/clusterIssues" component={ClusterIssuesWrapper} />

                        <Route path="/eventBridge" component={EventBridge} />
                        <Route path="/autoPatcher" component={AutoPatcher} />

                        <Route path="/icpsa/pipelineUsage" component={PipelineUsage} />
                        <Route path="/icpsa/deploymentInsights" component={DeploymentInsights} />
                        <Route path="/icpsa/versionControl" component={VersionControl} />

                        <Route path="/icpsa/platformAlerts" component={PlatformAlerts} />
                        <Route path="/icpsa/platformInsights" component={PlatformInsights} />
                        <Route path="/icpsa/platformMetrics" component={PlatformMetrics} />

                        <Route path="/gitHubOrganizations" component={GitHubOrganizations} />
                        <Route path="/gitHubRepos" component={GitHubRepos} />
                        <Route path="/gitHubRepoDetails" component={GitHubRepoDetails} />

                        <Route path="/DO" component={DoIndex} />
                        <Route path="/superNoc" component={TelcoDashboard} />

                        <Route path="/">
                            <Dashboard />
                        </Route>
                    </Switch>
                </>
            </Content>
        </>
    );
};
export default Routes;
