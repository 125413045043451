import Context from "Context/Context";
import { useContext } from "react";

export default function GetPermissions(scope) {

    const Tenants = JSON.parse(sessionStorage.getItem("tenants"));

    const context = useContext(Context)

    const permission = context.UserPermissions !== "" && context.UserPermissions.some((res) => {
        // const Subhub = scope.title !== undefined ? `${parent}_${scope.title.replace(/\s/g, '')}` : scope.replace(/\s/g, '');
        // return res.toLowerCase().includes(Subhub.toLowerCase())
        return scope !== undefined && scope.includes(res);
    })

    let feature = false;
    Tenants.length > 0 && Tenants.forEach((feat) => {
        sessionStorage.getItem("tenant") === feat.name && feat.features.forEach((pre) => {
            // const Subhub1 = scope.title !== undefined ? `${parent}_${scope.title.replace(/\s/g, '')}` : scope.replace(/\s/g, '');
            // if (pre.id.toLowerCase().includes(Subhub1.toLowerCase())) {
            if (scope !== undefined && scope.includes(pre.id)) {
                feature = pre.disabled ? false : true;
            }
        })
    })

    return permission && feature;
}