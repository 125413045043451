import { ReactKeycloakProvider } from "@react-keycloak/web";
import Permissions from "Roles-n-Permissions/Permissions";
import Keycloak from "keycloak-js";
import React, { Suspense, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import LandingPage from "./Components/landingPage/LandingHome";
import Context from "./Context/Context";
import { environment } from "./set-env";

const App = () => {
  const dispatch = useDispatch();
  const context = useContext(Context);
  sessionStorage.setItem("localeLanguage", "en");
  // const keycloak_ENV = process.env.REACT_APP_KEYCLOAK;
  // const BFF_ENV = process.env.REACT_APP_BFF;
  // const SVC_ENV = process.env.REACT_APP_SVC;

  useEffect(() => {
    getEnvVariable();
  }, []);

  function getQueryParams(url) {
    const params = {};
    const queryString = url.split('?')[1];
    if (queryString) {
      queryString.split('&').forEach(param => {
        const [key, value] = param.split('=');
        params[decodeURIComponent(key)] = decodeURIComponent(value);
      });
    }
    return params;
  }

  const checkForTenant = () => {
    if (window.location.hash.split("tenant/")[1] !== undefined && sessionStorage.getItem("setLogin") !== "true") {
      const spli = window.location.href.split("?")[0];
      sessionStorage.setItem("setLogin", "true")
      sessionStorage.setItem("defaultClient", spli.split("tenant/")[1])
      const url = window.location.href;
      const params = getQueryParams(url);
      sessionStorage.setItem("teamId", JSON.stringify(params))
      context.updateLogin(true);
    }
  };

  // uiBaseUrl: 'REACT_UI_ROOT_REPLACE',
  // bffBaseUrl: 'REACT_BFF_ROOT_REPLACE',
  // svcBaseUrl: 'REACT_SVC_ROOT_REPLACE',
  // keycloakBaseUrl: 'REACT_KEYCLOAK_ROOT_REPLACE',

  // const getKeycloakURL = () => {
  //   return environment.keycloakBaseUrl === "REACT_APP_KEYCLOAK_REPLACE" ? keycloak_ENV : environment.keycloakBaseUrl;
  // }
  // const getBffUrl = () => {
  //   return environment.bffBaseUrl === "REACT_APP_BFF_REPLACE" ? BFF_ENV : environment.bffBaseUrl;
  // }
  // const getSvcURL = () => {
  //   return environment.svcBaseUrl === "REACT_APP_SVC_REPLACE" ? SVC_ENV : environment.svcBaseUrl;
  // }

  const getEnvVariable = () => {

   // let baseURL = environment.apiBaseUrl === "REACT_APP_ROOT_REPLACE" ? process.env.REACT_APP_ROOT : environment.apiBaseUrl;
   // let keycloakURL = process.env.REACT_APP_KEYCLOAK;
   // let BffUrl = process.env.REACT_APP_BFF;
   // let svcURL = process.env.REACT_APP_SVC;

  // Start Changes for moving to NGINX server
    let baseURL = window._env_.REACT_APP_ROOT;
    let keycloakURL = window._env_.REACT_APP_KEYCLOAK;
    let BffUrl = window._env_.REACT_APP_BFF;
    let svcURL = window._env_.REACT_APP_SVC;
  // End Changes for moving to NGINX server

    // let keycloakURL = getKeycloakURL();
    // let BffUrl = getBffUrl();
    // let svcURL = getSvcURL();

    https://aiops-insights-bff-aiops.aiops-dev2-85ee131ed8e71cabc202e5781fab5c58-0000.eu-de.containers.appdomain.cloud/bff/keycloak

    //sessionStorage.setItem("defaultRealm", process.env.REACT_DEFAULT_TENANT);
    sessionStorage.setItem("defaultRealm", process.env.REACT_APP_KEYCLOAK_REALM);
    sessionStorage.setItem("defaultClient", process.env.REACT_APP_KEYCLOAK_RESOURCE);
    if (window.location.hash.split("tenant/")[1]) {
      console.log("window.location.hash.split(\"tenant / \")[1] ->>> ", window.location.hash.split("tenant/")[1])
      if (window.location.hash.split("tenant/")[1].split("&")[0]) {
        const hash = window.location.hash.split("tenant/")[1].split("&")[0]
        const removeStr = hash.substring(0, hash.indexOf('?'))
        sessionStorage.setItem("tenant", removeStr);
      } else {
        sessionStorage.setItem("tenant", window.location.hash.split("tenant/")[1]);
      }
    } else {
      sessionStorage.setItem("tenant", sessionStorage.getItem("tenant") ? sessionStorage.getItem("tenant") : process.env.REACT_APP_KEYCLOAK_RESOURCE);
    }
  //sessionStorage.setItem("doUrl", process.env.REACT_APP_DOURL);
  //sessionStorage.setItem("blueBuddy", process.env.REACT_APP_BLUEBUDDY)
  // NGINX Changes  
    sessionStorage.setItem("doUrl", window._env_.REACT_APP_DOURL);
    sessionStorage.setItem("blueBuddy",window._env_.REACT_APP_BLUEBUDDY)

    sessionStorage.setItem("keycloak", keycloakURL);
    sessionStorage.setItem("bff", BffUrl);
    sessionStorage.setItem("svc", svcURL + "/svc");
    // sessionStorage.setItem("bff", "http://localhost:8000" + "/bff");
    // sessionStorage.setItem("svc", "http://localhost:8080" + "/svc");

    sessionStorage.setItem("majorVersion", "1");
    sessionStorage.setItem("minorVersion", "13.3");
    sessionStorage.setItem("buildNumber", "495");

    dispatch({
      type: "STORE_SECRET",
      payload: "cw_0x689RpI-jtRR7oE8h_eQsKImvJapLeSbXpwF4e4=",
    });
    dispatch({ type: "STORE_TOKEN", payload: "ya29.a0AfB_byC8k2puqP0an2vWJF3qWfHHqyAcYdBcuBgJy0haoIqHJP3VziFA4BNwCqAbZikKdFbuRvjQAxaCbodXO6TnrIcNw49R8nwabdrIErQDPl3bgMZZ6qVZ_T0_E62KB8r9cjfynx0vb8bx-rTVQpfZGPXIOux3buXWg6OGxpenxQaCgYKAV4SARISFQHsvYlsJHv5AKr1DWwzqeVtERkN3w0181" });
    checkForTenant();
  };

  const getKeycloak = () => {
    const keycloakURL = sessionStorage.getItem("keycloak");
    const keycloak = new Keycloak({
      url: keycloakURL,
      // realm: window.location.pathname.split("tenant/")[1]
      //   ? window.location.pathname.split("tenant/")[1]
      //   : process.env.REACT_APP_KEYCLOAK_REALM,
    //  realm: process.env.REACT_APP_KEYCLOAK_REALM,
    //  clientId: process.env.REACT_APP_KEYCLOAK_RESOURCE//sessionStorage.getItem("tenant")//sessionStorage.getItem("defaultClient") !== null ? sessionStorage.getItem("defaultClient") : process.env.REACT_APP_KEYCLOAK_RESOURCE,
    // NGINX Changes
      realm: window._env_.REACT_APP_KEYCLOAK_REALM,
      clientId: process.env.REACT_APP_KEYCLOAK_RESOURCE//sessionStorage.getItem("tenant")//sessionStorage.getItem("defaultClient") !== null ? sessionStorage.getItem("defaultClient") :window._env_.REACT_APP_KEYCLOAK_RESOURCE
    });
    // keycloak.clientSecret = "your-client-secret"
    let kcLogin = keycloak.login;
    keycloak.login = (options) => {
      options.idpHint = "ibmid";
      kcLogin(options);
    };
    return keycloak;
  };

  return (
    <Context.Consumer>
      {(context) => (
        <>
          {(context.LoginSuccessful === true || sessionStorage.getItem("setLogin") === "true") ? (
            <ReactKeycloakProvider
              authClient={getKeycloak()}
              initOptions={{
                onLoad: "login-required",
                checkLoginIframe: false,
              }}
            >
              <Suspense fallback={<div></div>}>
                {/* <Navigation /> */}
                <Permissions />
              </Suspense>
            </ReactKeycloakProvider>
          ) : (
            <LandingPage />
          )}
        </>
      )}
    </Context.Consumer>
  )
}

export default App;
